import { z } from 'zod';

export const SactionSchema = z.object({
  ID: z.string().uuid(),
  CreatedAt: z.string(),
  Details: z.string(),
  FullName: z.string(),
  Attributes: z.object({
    PEP: z.boolean(),
    RCA: z.boolean(),
    Sanctioned: z.boolean(),
  }),
});

export type Saction = z.infer<typeof SactionSchema>;

export const FindSactionsResponseSchema = z.array(SactionSchema);

export type FindSactionsResponse = z.infer<typeof FindSactionsResponseSchema>;

export const FindSactionsRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

export type FindSactionsRequest = z.infer<typeof FindSactionsRequestSchema>;
