import { AnonymousUserLDContext, UserLDContext } from '../LDContext.types';

export const getAnonymousUserLDContext = (): {
  user: AnonymousUserLDContext;
} => ({
  user: {
    // Generating anonymous users
    // https://launchdarkly.com/docs/sdk/features/anonymous#javascript
    kind: 'user',
    anonymous: true,
  } as AnonymousUserLDContext,
});

export const getUserLDContext = (uuid: string, email?: string) => ({
  user: {
    kind: 'user',
    key: uuid,
    uuid,
    email,
  } as UserLDContext,
});
