import { clsx } from 'clsx';
import { ReactElement, Ref } from 'react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { InputSize, InputStateVariant } from '../InputProps.types';
import styles from './radio.module.scss';

type RadioProps = {
  name?: string;
  className?: string;
  state?: InputStateVariant;
  size?: InputSize;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  id?: string;
  _ref?: Ref<HTMLButtonElement>;
} & HTMLDataAttributes;

/**
 * Radio component
 */
export const Radio = ({
  className,
  state,
  size = 'l',
  value = '',
  _ref,
  ...props
}: RadioProps): ReactElement => {
  return (
    <RadixRadioGroup.Item
      className={clsx(
        styles.radio,
        className
      )}
      data-input-state={state}
      data-size={size}
      value={value}
      {...props}
      ref={_ref ?? undefined}
    >
      <RadixRadioGroup.Indicator className={styles.indicator} />
    </RadixRadioGroup.Item>
  );
};
