import { Text } from '@axo/ui-core/components/typography';
import { Radio } from '@axo/ui-core/components/input/Radio';
import { InputStateVariant } from '@axo/ui-core/components/input/InputProps.types';
import clsx from 'clsx';
import { ForwardRefRenderFunction, ReactNode, forwardRef, useId } from 'react';
import { IInputProps } from '../../models/input.model';
import styles from './RadioUI.module.scss';

export type RadioType = 'radio' | 'button' | 'both';

export interface IRadioUI extends IInputProps<HTMLInputElement, string> {
  radioStyle?: RadioType;
  checked?: boolean;
  classes?: ClassNamesProp;
  children?: ReactNode;
  buttonHeight?: 'large' | 'regular';
  label?: string;
  state?: InputStateVariant;
}

type ClassNamesProp = {
  root?: string;
};

export const RadioUI: ForwardRefRenderFunction<HTMLButtonElement, IRadioUI> = (
  {
    radioStyle,
    children,
    classes,
    buttonHeight,
    label,
    state,
    ...props
  },
  ref
) => {
  const id = useId();

  return (
    <div
      data-type={radioStyle}
      data-size={buttonHeight}
      className={clsx(styles.radioWrapper, classes?.root)}
    >
      <Radio
        className={styles.radio}
        size={'s'}
        state={state}
        id={`radio-${id}`}
        _ref={ref}
        {...props}
      />
      {children && <label htmlFor={`radio-${id}`}><Text size={'s'}>{children}</Text></label>}
    </div>
  );
};

export default forwardRef(RadioUI);
