import {
  AnalyticsEvent,
  AnalyticsIdentify,
  IAnalytics,
} from '../Analytics.types';
import {
  IAnalyticsService,
  IAnalyticsServiceParams,
} from '../AnalyticsService.types';
import { waitUntil } from '../utils/waitUntil';
import { ClarityFunction } from './Clarity.types';

/**
 * Clarity Service
 *
 * @see https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
 */
export const ClarityService: IAnalyticsService = (
  params: IAnalyticsServiceParams = {}
): IAnalytics => {
  const getClarity = (): ClarityFunction | undefined =>
    (window as typeof window & { clarity?: ClarityFunction }).clarity;
  const getHasServicePresent = (): boolean =>
    typeof getClarity() === 'function';

  const waitForInitialization = () => waitUntil(isInitialized);
  const isInitialized = getHasServicePresent;

  waitForInitialization().then(() => window.clarity('consent'));

  const track = async (e: AnalyticsEvent) => {
    if (getHasServicePresent()) {
      window.clarity('event', e.event);
    } else {
      console.log('ClarityService is not available for tracking events');
    }
  };

  /**
   * Identify a user by their unique ID.
   *
   * @see https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api
   */
  const identify = async ({ uuid, email }: AnalyticsIdentify) => {
    if (getHasServicePresent()) {
      await window.clarity('identify', uuid, null, null, uuid);

      window.clarity('set', 'user_id', uuid);
      email && window.clarity('set', 'email', email);
    } else {
      console.warn('ClarityService is not available for identifying users');
    }
  };

  return {
    waitForInitialization,
    isInitialized,
    track,
    identify,
  };
};
