import { getAnonymousUserLDContext } from './getUserLDContext';
import { basicLogger, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { LDContextKind } from '../LDContext.types';

type getLDConfigParams = {
  clientSideID: ProviderConfig['clientSideID'];
  context: Partial<Record<LDContextKind, unknown>>;
  isProduction?: boolean;
};

export const getLDConfig = ({
  clientSideID,
  context,
  isProduction = true,
}: getLDConfigParams): ProviderConfig => {
  const ldConfig: ProviderConfig = {
    clientSideID,
    options: {
      bootstrap: 'localStorage' as const,
      logger: basicLogger({ level: isProduction ? 'warn' : 'info' }),
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    context: {
      kind: 'multi',
      ...context,
    },
    timeout: 1,
  } satisfies ProviderConfig;

  return ldConfig;
};

export const getLDConfigWithAnonymousUser = ({
  clientSideID,
  context,
  isProduction = true,
}: getLDConfigParams): ProviderConfig =>
  getLDConfig({
    clientSideID,
    context: {
      ...context,
      ...getAnonymousUserLDContext(),
    },
    isProduction,
  });
