type Args<T> =
  | {
      baseURL: string;
      path: string;
      token?: string;
      method: 'GET';
      body?: never;
      headers?: Record<string, string>;
      searchParams?: Record<string, string>;
    }
  | {
      baseURL: string;
      path: string;
      token?: string;
      method: 'POST' | 'PATCH' | 'PUT' | 'DELETE';
      body?: T;
      headers?: Record<string, string>;
      searchParams?: Record<string, string>;
    };
export const send = async <Body, Return = Body>({
  baseURL,
  path,
  token,
  method,
  body,
  headers,
  searchParams,
}: Args<Body>): Promise<Return> => {
  headers = {
    'Content-Type': 'application/json',
    ...(headers ? headers : ''),
  };
  const cleanSearchParamsObject = new URLSearchParams(
    Object.fromEntries(
      Object.entries(searchParams ?? {}).filter(
        ([, value]) => value !== undefined,
      ),
    ),
  );
  const searchParamsString =
    cleanSearchParamsObject.size > 0 ? `?${cleanSearchParamsObject}` : '';
  const url = new URL(path, baseURL) + searchParamsString;

  if (token) headers['Authorization'] = token;
  try {
    const response = await fetch(url, {
      method,
      mode: 'cors',
      headers: headers,
      body: body !== undefined ? JSON.stringify(body) : undefined,
    });
    if (!response.ok) {
      const body = await response.json();
      const errorMessage = response.statusText
        ? response.statusText
        : body.message;
      if (response.status === 429) {
        console.warn(`Warning: Too many requests on ${method} ${path}.`);
        return undefined as Return;
      }
      throw new Error(`Error: ${response.status}: ${errorMessage}`);
    }
    const contentType = response.headers.get('Content-Type');
    if (contentType?.includes('application/json')) {
      return await response.json();
    }
    if (contentType?.includes('text/')) {
      return (await response.text()) as Return;
    }
    return undefined as Return;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('429')) {
        console.warn(
          `Warning: Too many requests to ${method} ${path}. Muting error.`,
        );
        return undefined as Return;
      }
      throw new Error(`Failed to ${method} ${path}; ${error?.message}`);
    }
    throw new Error(`Failed to ${method} ${path}`);
  }
};
