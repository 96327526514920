import { z } from 'zod';
import { MarketCountryCode } from '@axo/shared/types';
import * as loan_application from '../loan_application';

export const CallOrderSchema = z.object({
  ID: z.string().uuid(),
  CreatedAt: z.string(),
  LoanApplicationID: z.string().uuid(),
  ClientID: z.string().uuid(),
  MarketCountry: z.nativeEnum(MarketCountryCode),
  LoanApplicationStatus: z.nativeEnum(loan_application.ApplicationStatus),
  CustomerPhoneNumber: z.string(),
  Tags: z.array(z.string()).nullable(),
});

export type CallOrder = z.infer<typeof CallOrderSchema>;

export const CreateCallOrderRequestSchema = CallOrderSchema.pick({
  LoanApplicationID: true,
  ClientID: true,
  MarketCountry: true,
  LoanApplicationStatus: true,
  CustomerPhoneNumber: true,
  Tags: true,
});

export type CreateCallOrderRequest = z.infer<
  typeof CreateCallOrderRequestSchema
>;

export const FindCallOrderRequestSchema = CallOrderSchema.pick({
  LoanApplicationID: true,
  CustomerPhoneNumber: true,
});

export type FindCallOrderRequest = z.infer<typeof FindCallOrderRequestSchema>;

export const FindCallOrderResponseSchema = z.object({
  Items: z.array(CallOrderSchema).nullable(),
});

export type FindCallOrderResponse = z.infer<typeof FindCallOrderResponseSchema>;

export const CallRecordSchema = z.object({
  ID: z.string().uuid(),
  CreatedAt: z.string(),
  LoanApplicationID: z.string().uuid(),
  ClientID: z.string().uuid(),
  MarketCountry: z.nativeEnum(MarketCountryCode),
  LoanApplicationStatus: z.nativeEnum(loan_application.ApplicationStatus),
  CustomerPhoneNumber: z.string(),
  Tags: z.array(z.string()).nullable(),
  ExternalRef: z.string().optional().nullable(),
  OrderID: z.string().uuid().optional().nullable(),
  Outbound: z.boolean(),
  OrderedAt: z.string().optional().nullable(),
  InitiatedAt: z.string().optional().nullable(),
  AnsweredAt: z.string().optional().nullable(),
  Answered: z.boolean().optional().nullable(),
  WentToVoicemail: z.boolean().optional().nullable(),
  EndedAt: z.string().optional().nullable(),
  StateDescription: z.string().optional(),
});

export type CallRecord = z.infer<typeof CallRecordSchema>;

export const PatchCallRecordRequestSchema = CallRecordSchema.pick({
  Tags: true,
  ExternalRef: true,
  OrderID: true,
  Outbound: true,
  OrderedAt: true,
  InitiatedAt: true,
  AnsweredAt: true,
  EndedAt: true,
  StateDescription: true,
}).partial();

export type PatchCallRecordRequest = z.infer<
  typeof PatchCallRecordRequestSchema
>;

export const CreateCallRecordRequestSchema = CallRecordSchema.pick({
  LoanApplicationID: true,
  ClientID: true,
  MarketCountry: true,
  LoanApplicationStatus: true,
  CustomerPhoneNumber: true,
  Tags: true,
  Outbound: true,
});

export type CreateCallRecordRequest = z.infer<
  typeof CreateCallRecordRequestSchema
>;

export const FindCallRecordsRequestSchema = CallRecordSchema.pick({
  LoanApplicationID: true,
  CustomerPhoneNumber: true,
}).partial();

export type FindCallRecordsRequest = z.infer<
  typeof FindCallRecordsRequestSchema
>;

export const FindCallRecordsResponseSchema = z.object({
  Items: z.array(CallRecordSchema).nullable(),
});

export type FindCallRecordsResponse = z.infer<
  typeof FindCallRecordsResponseSchema
>;

export const CallTagSchema = z.object({
  ID: z.string().uuid(),
  Name: z.string(),
  Disabled: z.boolean(),
  MarketCountry: z.nativeEnum(MarketCountryCode),
  Roles: z.array(z.string()).nullable().optional(),
});

export type CallTag = z.infer<typeof CallTagSchema>;

export const FindCallTagsResponseSchema = z.object({
  Items: z.array(CallTagSchema).nullable(),
});

export type FindCallTagsResponse = z.infer<typeof FindCallTagsResponseSchema>;
