export enum ApplicationStatus {
  ApplicationStatusStarted = '100 Started',
  ApplicationStatusPendingCoApplicant = '155 Pending co-applicant',
  ApplicationStatusPendingReceiptCustomerInputComplete = '160 Pending receipt. Customer input complete',
  ApplicationStatusCloned = '161 Cloned',
  ApplicationStatusBlocked = '162 Blocked',
  ApplicationStatusValidationFailed = '169 Validation failed',
  ApplicationStatusPendingReceiptFetchingExternalData = '180 Pending receipt. Fetching external data',
  ApplicationStatusFetchingExternalDataFailed = '181 Fetching external data failed',
  ApplicationStatusStartedExpired = '189 Expired',
  ApplicationStatusWithdrawnByCustomerIncomplete = '190 Withdrawn by customer',
  ApplicationStatusBlockedBySanctions = '198 Blocked by sanctions',
  ApplicationStatusFraud = '199 Fraud',
  ApplicationStatusCompleted = '200 Completed',
  ApplicationStatusApplicationReviewManualAdjustment = '201 Application review. Manual adjustment',
  ApplicationStatusApplicationReviewAutomaticAdjustment = '209 Application review. Automatic adjustment',
  ApplicationStatusPendingRegistrationWithBanks = '210 Pending registration with banks',
  ApplicationStatusNotRegisteredWithAnyBank = '270 Not Registered with Any Bank',
  ApplicationStatusCompletedCancelledByAxo = '280 Cancelled By Axo',
  ApplicationStatusCompletedExpired = '289 Expired',
  ApplicationStatusCompletedWithdrawnByCustomer = '290 Withdrawn By Customer',
  ApplicationStatusDuplicate = '299 Duplicate',
  ApplicationStatusRegistered = '300 Registered',
  ApplicationStatusGrooming = '305 Grooming',
  ApplicationStatusPendingBankApproval = '310 Pending bank approval',
  ApplicationStatusNotApprovedByAnyBank = '370 Not Approved by Any Bank',
  ApplicationStatusRegisteredCancelledByAxo = '380 Cancelled By Axo',
  ApplicationStatusClonedForResubmissionWithCoApplicant = '388 Cloned For Resubmission With CoApplicant',
  ApplicationStatusRegisteredExpired = '389 Expired',
  ApplicationStatusRegisteredWithdrawnByCustomer = '390 Withdrawn By Customer',
  ApplicationStatusOfferApproved = '400 Offer approved',
  ApplicationStatusPendingCustomerAccept = '410 Pending customer accept',
  ApplicationStatusNotAcceptedByCustomer = '470 Not accepted by customer',
  ApplicationStatusApprovedCancelledByAxo = '480 Cancelled By Axo',
  ApplicationStatusApprovedExpired = '489 Expired',
  ApplicationStatusApprovedWithdrawnByCustomer = '490 Withdrawn By Customer',
  ApplicationStatusOfferAccepted = '500 Offer accepted',
  ApplicationStatusPendingBankApprovalForDisbursement = '510 Pending bank approval for disbursement',
  ApplicationStatusNotApprovedForDisbursementByAnyBank = '570 Not approved for disbursement by any bank',
  ApplicationStatusOfferAcceptedCancelledByAxo = '580 Cancelled by Axo',
  ApplicationStatusOfferAcceptedClonedForResubmissionWithCoApplicant = '588 Cloned For Resubmission With CoApplicant',
  ApplicationStatusOfferAcceptedExpired = '589 Expired',
  ApplicationStatusOfferAcceptedWithdrawnByCustomer = '590 Withdrawn by customer',
  ApplicationStatusLoanApprovedForDisbursement = '600 Loan approved for disbursement',
  ApplicationStatusPendingDisbursement = '610 Pending disbursement',
  ApplicationStatusNotDisbursedByAnyBank = '670 Not disbursed by any bank',
  ApplicationStatusDisbursementCancelledByAxo = '680 Disbursement cancelled by Axo',
  ApplicationStatusDisbursementClonedForResubmissionWithCoApplicant = '688 Cloned For Resubmission With CoApplicant',
  ApplicationStatusDisbursementExpired = '689 Expired',
  ApplicationStatusDisbursementWithdrawnByCustomer = '690 Disbursement withdrawn by customer',
  ApplicationStatusLoanDisbursed = '700 Loan disbursed',
  ApplicationStatusLoanClawbacked = '800 Clawbacked',
}

export enum StatusGroup {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Registered = 'Registered',
  Accepted = 'Accepted',
  Disbursing = 'Disbursing',
  Incomplete = 'Incomplete',
  Grooming = 'Grooming',
  Disbursed = 'Disbursed',
  Approved = 'Approved',
  Cloned = 'Cloned',
  Blocked = 'Blocked',
  Expired = 'Expired',
  ClonedForResubmission = 'ClonedForResubmission',
  WithdrawnByCustomer = 'WithdrawnByCustomer',
}

export type LoanApplicationMap = {
  [key in ApplicationStatus]: {
    Code: number;
    Name: string;
    Description: string;
    Groups: (keyof typeof StatusGroup)[];
  };
};
